import React, { useState, useEffect } from "react";
import Context from "../../Context";
import {
  ContentContainer,
  Container,
  Typo,
  Flex,
  Breadcrumb,
  ModalLoading,
  Button,
  Progress,
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../components";
import { monthTh } from "../../util";
import theme from "../../themes";
import { IoIosArrowForward } from "react-icons/io";
import { ProgressBar, Step } from "react-step-progress-bar";

export const Mission = () => {
  const { callApi, configs, profileState, setProfileState, go } = Context();

  const [isLoading, setIsLoading] = useState(true);
  const [isShowMyMission, setIsShowMyMission] = useState(false);
  const [allMissions, setAllMission] = useState([]);
  const [selectMission, setSelectMission] = useState({});
  const [myMission, setMyMission] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    (async () => {
      if (!profileState.admin) return go("/");
      const result = await callApi("missions-getUserMissions");
      setAllMission(result.missions);
      setMyMission(result.userMissionClaim);
      setIsLoading(false);
    })();
    return;
  }, []);

  if (isLoading || allMissions.length === 0) {
    return (
      <>
        <img
          src="/images/loading-mission.svg"
          alt="loading"
          className="h-full w-full py-16 max-w-[1200px] max-h-[80vh]"
        />
        <ModalLoading />
      </>
    );
  }
  return (
    <Container>
      <Breadcrumb
        data={[{ path: "/", name: "หน้าแรก" }, { name: "ภารกิจพิชิตรางวัล" }]}
      />
      <p className="text-navy text-3xl text-center font-bold">
        ภารกิจพิชิตรางวัล
      </p>

      <br />
      <DialogMission
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        callApi={callApi}
        selectMission={selectMission}
      />
      <div className="flex justify-center bg-white p-2 rounded-full h-16 drop-shadow-md">
        <div
          className={`flex justify-center items-center grow rounded-full cursor-pointer ${
            !isShowMyMission && "bg-yellow-400"
          }`}
          onClick={() => setIsShowMyMission(false)}
        >
          <p className="text-navy text-lg font-medium">ภารกิจที่มีอยู่</p>
        </div>
        <div
          className={`flex justify-center items-center grow rounded-full cursor-pointer ${
            isShowMyMission && "bg-yellow-400"
          }`}
          onClick={() => setIsShowMyMission(true)}
        >
          <p className="text-navy text-lg font-medium">ภารกิจที่จบแล้ว</p>
        </div>
      </div>

      {isShowMyMission ? (
        <div className="flex flex-col gap-4 my-6">
          {myMission &&
            myMission.map((order) => {
              return (
                <div
                  className={`min-h-[100px] w-full bg-white p-8 rounded-xl drop-shadow-md cursor-pointer`}
                  key={order.id}
                >
                  <div className="flex gap-8">
                    <img
                      className="w-24 h-24 mx-auto rounded-2xl"
                      src="/images/missionExample.png"
                      alt="mission"
                    />
                    <div className="flex flex-col justify-between w-full">
                      <p className="text-xl font-medium">
                        {order.mission.name}
                      </p>

                      <p
                        className="text-success"
                        onClick={() => go("/coupon/my_coupon")}
                      >
                        รับรางวัลแล้ว
                      </p>
                      <div
                        className={`
                       flex items-center bg-success justify-center w-fit py-1 px-2 rounded-[0.5rem] text-xs text-white font-semibold`}
                      >
                        {order.id.slice(-5).toUpperCase()}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          {allMissions
            .filter((x) => x.status === "inactive")
            .map((data) => {
              return (
                <div
                  className={`min-h-[100px] w-full bg-white p-8 rounded-xl drop-shadow-md cursor-pointer opacity-50`}
                  key={data.id}
                >
                  <div className="flex gap-8">
                    <img
                      className="w-24 h-24 mx-auto rounded-2xl"
                      src="/images/missionExample.png"
                      alt="mission"
                    />
                    <div className="flex flex-col justify-between w-full">
                      <p className="text-xl font-medium">{data.name}</p>

                      <p
                        className="text-gray-400"
                        onClick={() => {
                          setSelectMission(data);
                          setIsOpen(true);
                        }}
                      >
                        ไม่พร้อมใช้งาน
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      ) : (
        <AllMissions
          allMissions={allMissions}
          setIsOpen={setIsOpen}
          setSelectMission={setSelectMission}
        />
      )}
    </Container>
  );
};

const DialogMission = ({ isOpen, setIsOpen, selectMission = {}, callApi }) => {
  const submitMission = async (missionId) => {
    await callApi("missions-createMissionOrder", { missionId });
  };

  if (!selectMission.name) return <></>;
  const percent =
    selectMission.status === "inactive"
      ? 0
      : selectMission.status === "complete"
      ? 100
      : (selectMission.currentCount * 100) / selectMission.requiredOrder;

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogContent>
        <div className="flex flex-col gap-4">
          <img
            className="w-24 h-24 mx-auto rounded-2xl"
            src="/images/missionExample.png"
            alt="mission"
          />
          <p className="text-lg text-center text-navy font-bold">
            {selectMission.name}
          </p>
          <ProgressBar
            className="h-[5px]"
            percent={percent}
            filledBackground={
              selectMission.status === "complete"
                ? theme.colors.success
                : theme.colors.navy
            }
          >
            {[...Array(selectMission.requiredOrder + 1).keys()].map(
              (x, index) => {
                return (
                  <Step transition="scale" key={selectMission.id + index}>
                    {({ accomplished }) => (
                      <div
                        className={
                          selectMission.status === "complete"
                            ? "bg-transparent border-0 w-0 h-0"
                            : index === 0
                            ? "bg-transparent border-0 w-0 h-0"
                            : `w-3 h-3 rounded-full  border-2 
                  ${
                    accomplished
                      ? "border-[#003A9E] bg-blue-200"
                      : "border-[#C4C4C4] bg-white"
                  }`
                        }
                      />
                    )}
                  </Step>
                );
              }
            )}
          </ProgressBar>
          <div className="flex justify-between mt-[-10px]">
            <Typo.Body7 color="black">จำนวนภารกิจที่สามารถทำได้</Typo.Body7>

            <Typo.Body7 color="black">
              {selectMission.userClaim + " / " + selectMission.limitPerUser}
            </Typo.Body7>
          </div>
          <div className="flex justify-between">
            <div>
              <p className="font-medium">ระยะเวลาทำภารกิจ</p>
              <p className="text-sm my-2">
                {selectMission.createdAtDate.split("-").join("/")} -{" "}
                {selectMission.expiredAtDate || "00/00/00"}
              </p>
            </div>
            <div className="flex flex-col items-end">
              <p className="font-medium">สถานะ</p>
              <div
                className={`
                       flex items-center mx-auto ${
                         percent >= 100
                           ? "bg-success"
                           : percent > 0
                           ? "bg-yellow-500"
                           : "bg-gray-600"
                       } justify-center w-fit py-1 px-2 rounded-[0.5rem] text-xs text-white font-semibold`}
              >
                {percent >= 100
                  ? "ทำภารกิจสำเร็จ"
                  : percent > 0
                  ? "กำลังทำภารกิจ"
                  : "ยังไม่สำเร็จ"}
              </div>
            </div>
          </div>
          <div>
            <p className="font-medium">รางวัลของภารกิจนี้</p>
            <p className="text-sm my-2">{selectMission.reward.name}</p>
          </div>
          <div>
            <p className="font-medium">เงื่อนไขภารกิจ</p>
            <p className="text-sm my-2">{selectMission.info}</p>
          </div>

          <Button
            disabled={selectMission.status !== "active" || percent < 100}
            width="100%"
            background={"gradient2"}
            color="white"
            weight="bold"
            size="12px"
            hoverBg="white"
            hoverColor="navy"
            hoverBorder="navy"
            onClick={() => {
              // setSelectMission(mission);
              // setIsOpen(true);
              if (percent >= 100) {
                submitMission(selectMission.id);
              }
            }}
          >
            รับรางวัล
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

const AllMissions = ({ allMissions, setIsOpen, setSelectMission }) => {
  return (
    <div className="flex flex-col gap-4 my-6">
      {allMissions
        .filter((x) => x.status !== "inactive")
        .sort((a, b) => a.quantity - b.quantity)
        .map((mission) => {
          const missionPercent =
            // mission.status === "inactive"
            //   ? 0
            //   :
            mission.status === "complete"
              ? 100
              : (mission.currentCount * 100) / mission.requiredOrder;
          return (
            <div
              className={`min-h-[100px] w-full bg-white p-3 rounded-xl drop-shadow-md ${
                mission.status === "inactive"
                  ? "opacity-50 cursor-not-allowed"
                  : "cursor-pointer"
              }`}
              key={mission.name}
              onClick={() => {
                setSelectMission(mission);
                setIsOpen(true);
              }}
            >
              <div className="flex gap-3">
                <img
                  className="w-24 h-24 mx-auto rounded-2xl"
                  src="/images/missionExample.png"
                  alt="mission"
                />
                <div className="flex flex-col justify-around w-full">
                  <Typo.Body1 color="black">{mission.name}</Typo.Body1>
                  <div className="mt-3">
                    <ProgressBar
                      className="h-[5px]"
                      percent={missionPercent}
                      filledBackground={
                        mission.status === "complete"
                          ? "#019035"
                          : theme.colors.navy
                      }
                    >
                      {[...Array(mission.requiredOrder + 1).keys()].map(
                        (x, index) => {
                          return (
                            <Step transition="scale" key={mission.id + index}>
                              {({ accomplished }) => (
                                <div
                                  className={
                                    mission.status === "complete"
                                      ? "bg-transparent border-0 w-0 h-0"
                                      : index === 0
                                      ? "bg-transparent border-0 w-0 h-0"
                                      : `w-3 h-3 rounded-full  border-2 
                              ${
                                accomplished
                                  ? "border-[#003A9E] bg-blue-200"
                                  : "border-[#C4C4C4] bg-white"
                              }`
                                  }
                                />
                              )}
                            </Step>
                          );
                        }
                      )}
                    </ProgressBar>
                    <div className="flex justify-between mt-2">
                      <Typo.Body7 color="black">
                        จำนวนภารกิจที่สามารถทำได้
                      </Typo.Body7>

                      <Typo.Body7 color="black">
                        {mission.userClaim + " / " + mission.limitPerUser}
                      </Typo.Body7>
                    </div>
                  </div>
                </div>
              </div>

              {missionPercent >= 100 && mission.status === "active" && (
                <Button
                  className="mt-3"
                  width="100%"
                  background="gradient2"
                  color="white"
                  weight="bold"
                  size="12px"
                  hoverBg="white"
                  hoverColor="navy"
                  hoverBorder="navy"
                  onClick={
                    () => {
                      setSelectMission(mission);
                      setIsOpen(true);
                    }
                    // submitMission(mission.id)
                  }
                >
                  รับรางวัล
                </Button>
              )}
            </div>
          );
        })}
    </div>
  );
};
